// https://polygon-mainnet.infura.io/v3/5a0e1e011860401880d5984367e68fbf

// testnet

const Utils = {
    contract: "0xA363E7462527cC8A5C85218717f180235dA465c9",
    rpc: "https://rpc.cardona.zkevm-rpc.com",
    chainID: 2442
}


//Mainnet
/**
const Utils = {
    contract: "0xA72fA385141fE30f4246bB187B0eCdeb890531eE",
    rpc: "https://polygon-bor-rpc.publicnode.com",
    chainID: 137
}
*/

export default Utils;